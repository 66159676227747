.block-time-inputs{
  display: flex;
  flex-direction: column;
}

.block-time-input{
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

.block-time-payments {
  display: flex;
  flex-direction: column;
}

.block-time-payment-options {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 12px;
}

.block-time-proportional-payment{
  display: flex;
  flex-direction: column;
  margin-right: 12px;
}

.block-time-payment-info{
  margin-bottom: 8px;
}

.block-time-submit {
  margin-top: 8px;
}

.block-time-input label, .block-time-payments label {
  color: var(--chakra-colors-gray-600);
  font-weight: var(--chakra-fontWeights-semibold);
}

.time-input {
  width: 50px;
  height: 40px;
  padding: 5px;
  border-radius: var(--br-sm);
  border: 2px solid var(--black);
}
